import React, { useState, useEffect } from 'react';
import '../CSS/ImageSlider.css'

export default function ImageSlider() {

const [images, setImages] = useState([]);

  useEffect(() => {
      fetch(`/api/ImageSlider`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setImages(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
  }, []); 
    
return (
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    {images.map((item, index) => (
      <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
        {item.description !== 'Video' && (
          <img
            src={`/api/Admin/Uploads/BannerImages/${item.imgUrl}?timestamp=${new Date().getTime()}`}
            className="d-block img responsive"
            width="600"
            height="400"
            alt={item.title}
          />
        )}
        {item.description === 'Video' && (
          <video className="fullscreen-video"  autoPlay loop muted>
            <source src={`/api/Admin/Uploads/BannerImages/${item.imgUrl}`} type="video/mp4" width="1500" height="570" />
          </video>
        )}
      </div>
    ))}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

    );
}
