import React from 'react'
import play from '../Images/play (1).jpg'

export default function Ssports() {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>School Sports</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
  <h2>Unleash the Athlete Within</h2>
  <p>Fostering Team Spirit and Healthy Competition Through Sports</p>
</div>
<div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1400px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
        <p className="">
        "Embracing the age-old wisdom that a healthy body nurtures a vibrant mind, Green Valley Public School (GVPS) champions the holistic development of every student through the realm of sports. Our commitment to nurturing not just academic excellence, but also physical vitality and team spirit, is reflected in our well-crafted sports programs and state-of-the-art facilities.
        </p>
        <p>
        <h6 style={{ color: '#5fcf80', fontFamily: 'Poppins'}}>A Future Built on Team Spirit:</h6>
        Beyond fostering physical fitness, our sports program champions team spirit. Through practices, matches, and friendly competitions, students learn to work in harmony, support one another, and taste the sweet fruit of collective effort.
        </p>
        <p>
        <h6 style={{ color: '#5fcf80', fontFamily: 'Poppins'}}>Space to Excel, Freedom to Thrive:</h6>
        Our library isn't just a space; it's a gateway to exploration. Whether you're seeking a quiet corner to immerse yourself in words or a collaborative space to spark discussions, our library has it all. Discover the joy of flipping through pages, the thrill of uncovering new perspectives, and the power of becoming a lifelong learner.
        </p>
        <p>Join us on a journey where sportsmanship knows no bounds, where play is as important as study, and where the pursuit of excellence extends from the classroom to the field. Welcome to Green Valley Public School, where the spirit of champions is nurtured, and the roar of the playground echoes the dreams of tomorrow."</p>
        <p className="card-text"><small className="text-body-secondary">GREEN VALLEY PUBLIC SCHOOL</small></p>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
    <div className="image-containerss shadow  my-5 bg-white rounded">
      <img src={play} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
    </div>
 </div>
</div>
    </>
  )
}
