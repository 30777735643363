import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function ContactUs() {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientNo, setClientNo] = useState('');
  const [clientMssg, setClientMssg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleclientNameChange = (e) => {
    setClientName(e.target.value);
    };
  const handleclientEmailChange = (e) => {
    setClientEmail(e.target.value);
    };
  const handleclientNoChange = (e) => {
  setClientNo(e.target.value);
    };
  const handleclientMssgChange = (e) => {
    setClientMssg(e.target.value);
    };

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };
  
    useEffect(() => {
      console.log('successMessage', successMessage);
    }, [successMessage]);

    const handleSubmit = async (e) => {
      console.log('FormDataHandling called');
      e.preventDefault();
  
      if (isSubmitting) {
        return; 
      }
      setIsSubmitting(true); 
      setSuccessMessage('Our Team Will Contact You Soon.');
      setShowModal(true);
          setTimeout(() => {
            removeSuccessMessage();
          }, 2000);
    
      try {
        const response = await fetch(`/api/ContactUs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clientName, clientEmail, clientNo, clientMssg }),
        });
    
        if (response.ok) {
          console.log('data uploaded successfully');
          setClientName('');
          setClientEmail('');
          setClientNo('');
          setClientMssg(''); 
        } else {
          console.log("error in uploading")
        }
      } catch (error) {
        console.error('Error uploading data:', error);
      }
      setIsSubmitting(false);
    }
  return (
    <>
    {showModal && (
        <div className="modal-backdrop show"></div>
        )}
        <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
              <div className="modal-header">
                <h5 className="modal-title">Enquiry Send successfully.</h5>
              </div>
              <div className="modal-body">
                {successMessage && <p className='text-success'>{successMessage}</p>}
              </div>
            </div>
          </div>
        </div>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>Contact Us</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div id="contact" className="contact">
      <div className="container my-5" data-aos="fade-up">
        <div className="csection-title">
          <h2>Contact</h2>
          <p>Feel free to contact us for any query</p>
        </div>
        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6">
            <div className="info-box mb-4">
              <i className="bi bi-geo-alt-fill"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
              </svg></i>
              <h3>Our Address</h3>
              <p>NTPC GAIL Canal Road,</p>
              <p> Dibiyapur || Auraiya,  (U P) - 206244</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-envelope"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
              <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z"/>
              <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
              </svg></i>
              <h3>Email Us</h3>
              <p><Link className='nav-link' to="mailto:greenvalley.dibiyapur@gmail.com">greenvalley.dibiyapur@gmail.com</Link></p>
              <p><Link className='nav-link' to="mailto:greenconsultancy123@gmail.com">greenconsultancy123@gmail.com</Link></p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-phone-call"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
              </svg></i>
              <h3>Call Us</h3>
              <p><Link className='nav-link' to="tel:+917900500999">+91-7900-500-999</Link></p>
              <p><Link className='nav-link' to="tel:+917900500444">+91-7900-500-444</Link></p>
            </div>
          </div>
        </div>
        <div className="row" data-aos="fade-up" data-aos-delay="100">
        <div className="col-md-6 d-block m-auto">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.897786553034!2d79.53635377457142!3d26.619722872621868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399dfacf2553db9d%3A0x7e2052df7ac6be56!2sGreen%20Valley%20Public%20School!5e0!3m2!1sen!2sin!4v1693457400644!5m2!1sen!2sin" className='mapiframe' width="550" height="390"  style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='abc'></iframe>
        </div>
        <div id="contactForm" className="col-lg-6">
          <h3 style={ {textAlign:'center', color:'#2D50A4'}}>Reach Out to Green Valley School !</h3>
          <form className='php-email-form' onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col form-group">
                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars"  value={clientName}  onChange={handleclientNameChange} required />
                <div className="validate"></div>
              </div>
              <div className="col form-group">
                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" value={clientEmail}  onChange={handleclientEmailChange} required  />
                <div className="validate"></div>
              </div>
            </div>
            <div className="form-group">
              <input type="text" className="form-control" name="number" id="number" placeholder="Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" value={clientNo}  onChange={handleclientNoChange} required   />
              <div className="validate"></div>
            </div>
            <div className="form-group">
              <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"  value={clientMssg}  onChange={handleclientMssgChange} required ></textarea>
              <div className="validate"></div>
            </div>
            <div className="form-group d-none">
              <input type="text" className="form-control" name="first_name" />
              <div className="validate"></div>
            </div>
          <div className="text-center"><button type="submit" name="submit">Send Message</button></div>
        </form>
      </div>
    </div>
  </div>
</div>
    </>
  )
}
