import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '../App.css'

export default function TcForm() {
  const[pdfFile, setPdfFile] = useState(null)
  const[srno, setSrno] = useState([])
  const[dob, setDob] = useState('')
  const[pdfError, setPdfError] = useState('')

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTab: defaultTabs => {
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      // defaultTabs[1] // Thumbnails tab
    },
  });



  const handlesrno = (e) =>{
    setSrno(e.target.value)
  }
  const handledob = (e) =>{
    setDob(e.target.value)
  }
  const handlereset = () =>{
    setSrno('')
    setDob('')
  }

  const handleSearch = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`/api/TcForm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          srno,
          dob,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.recordset && data.recordset.length > 0 && data.recordset[0].PdfName) {
          setPdfFile(data.recordset[0].PdfName);
          setPdfError('');
        } else {
          setPdfFile(null);
          setPdfError('PDF not found for the provided SrNo and DOB.');
        }
      } else {
        console.error('Error fetching data');
      }
    } catch (error) {
      setPdfFile(null);
      setPdfError('Error fetching PDF: ' + error.message);
    }
  };
  

  return (
   <>
{/* <div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active carouselImg">
      <img src={} className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
    </div>
  </div>
</div> */}

{/* TC Form Section */}
<div className='container d-felx my-5 align-items-center form-inline'>
      <h1 className='text-center heady'>TC VERIFICATION</h1>
        <div className='head'>
            <h3>GVPS {pdfFile}</h3>
        </div>
        <div className='parent-container'>
      <form method="GET" className='row g-3 my-1 m-form1'>
      <div className='col-auto align-items-center'>
          <label  className='form-label' htmlFor="title">SR No<span>*</span>:</label>
      </div>
      <div className='col-auto'>
          <input  className='form-input form-control-sm' type="text" id="srNo"  value={srno} onChange={handlesrno}  required  />
        </div>
        <div className='col-auto'>
        <label  className='form-label' htmlFor="dob">Date Of Birth<span>*</span>:</label>
      </div>      
        <div className='col-auto'>
          <input  className='form-input form-control-sm' type="Date" id="dob" value={dob} onChange={handledob}  required  placeholder="mm/dd/1999" />
        </div>
        <div className='col-auto btn-mobile'>
      <button type='button' className='btn btn-success btn-md btnS'onClick={handleSearch}>Search</button>
      <button type='button' className='btn btn-danger btn-md btnR' onClick={handlereset}>Reset</button>
      </div>
      </form>
      </div>
      </div>

      {/* view pdf section */}
      <Worker  workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div className='container d-flex my-3'>
          <div className='pdf-viewer1'>
            {pdfFile ? (
              <Viewer fileUrl={`/api/Admin/Uploads/TransferCertificate/${pdfFile}`} plugins={[defaultLayoutPluginInstance]} defaultScale={1} />
            ) : (
              <div className='text-danger'>{pdfError || 'No File Selected Yet'}</div>
            )}
          </div>
        </div>
      </Worker>
   </>
  )
}
