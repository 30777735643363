import React, { useState, useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../CSS/SchlImgGallery.css';

export default function SchlImgGallery() {
   

    const [images, setImages] = useState([]);

    
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 2,
          autoplay: true,
          draggable: true,
          responsive: [
            {
              breakpoint: 1200, // Adjust the breakpoint as needed
              settings: {
                slidesToShow: 3, // Display 1 image per slide on smaller screens
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 668, // Adjust the breakpoint as needed
              settings: {
                slidesToShow: 1, // Display 1 image per slide on smaller screens
                slidesToScroll: 1,
              },
            },
          ],
       
        };

        useEffect(() => {
          fetch(`/api/SchlImgGallery`)
          .then(response => response.json())
          .then(data => {
              const dataArray = data.recordset
              if(Array.isArray(dataArray)){
                setImages(dataArray)
              }else {
                  console.error('Invalid data format from the API');
                }
              })
              .catch(error => {
                
              });
        }, []); 
    

       

  return (
   <>
   <div className="wrapper container">
   <Slider {...settings} className="image-slider">
        {images.map((image, index) => (
          <div key={index} className="slick-slide">
            <img
              src={`/api/Admin/Uploads/SchoolImageGallery/${image.gllryImgUrl}?timestamp=${new Date().getTime()}` }
              alt={image.schoolImgTitle} className='img1'
            />
          </div>
        ))}
        </Slider>
      </div>
   </>
  )
}
