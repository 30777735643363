import React from 'react'
import { Link } from 'react-router-dom';
import facebook from '../Images/logo/facebook.png'
import youtube from '../Images/logo/youtube.png'

export default function Footer() {
  return (
    <>
    <footer className="pt-5 mt-4 container text-white" style={{ maxWidth: '100%', margin:'0px',  backgroundColor:'#2D50A4' }}>
    <div className="row" style={{ backgroundColor:'rgb(248,249,250)'}}>
      <div className="col-9 offset-md-1 col-md-2 mb-3 pt-2 ">
        <h3 className='text-color'>Quick Links</h3>
        <ul className="nav flex-column ">
          <li className="nav-item mb-2 "><Link to="/" className="nav-link p-0 text-dark">Home</Link></li>
          <li className="nav-item mb-2"><Link to="/AboutUs" className="nav-link p-0 text-dark">About Us</Link></li>
          <li className="nav-item mb-2"><Link to="/SmartClass" className="nav-link p-0 text-dark">Facilities</Link></li>
          <li className="nav-item mb-2"><Link to="/ContactUs" className="nav-link p-0 text-dark">Contact Us</Link></li>
        </ul>
      </div>
      <div className="col-9 offset-md-1 col-md-4 mb-3 pt-2">
        <h3 className='text-color'>Reach Us</h3>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 text-dark">NTPC GAIL Canal Road,Dibiyapur || Auraiya,  (U P) - 206244</Link></li>
          <li className="nav-item mb-2"><Link to="tel:+917900500999" className="nav-link p-0 text-dark">(+91) 790 050 0999</Link></li>
          <li className="nav-item mb-2"><Link to="mailto:greenvalley.dibiyapur@gmail.com" className="nav-link p-0 text-dark">greenvalley.dibiyapur@gmail.com</Link></li>
          <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-dark">Monday - Saturday, 08:00AM - 02:00 PM</Link></li>
        </ul>
      </div>
      <div className="col-md-4  mb-3 pt-2">
          <h5 className='text-color'>Green Valley Public School</h5>
          <p className='text-dark'>Experience The Future OF Learning.</p>
          <div className="col-6 col-md-5 mb-3 pt-2 ">
          <ul className="nav flex-row">
            <li className="nav-item mb-2"><Link to='https://www.facebook.com/Gvpsdibiyapur21/'><img src={facebook} width="32" height="32" className="rounded-circle me-2" alt='img1'/></Link></li>
            <li className="nav-item mb-2"><Link to='https://www.youtube.com/channel/UCU5jlJE2nBEY7Vv-0iIvmsQ'><img src={youtube} width="32" height="32" className="rounded-circle me-2" alt='img3'/></Link></li>
          </ul>
      </div>
      </div>
    </div>
    <div className="d-flex flex-column flex-sm-row justify-content-between pt-2 " style={{ backgroundColor:'#2D50A4'}}>
      <p className='text-white text-center'>© 2023 GVPS, Inc. All rights reserved.</p>
      <p className='text-white text-center'><Link to='http://rayaninfosolutions.com/' className='nav-link'>Designed by: Rayan Info Solutions</Link></p>
    </div>
  </footer>
    </>
  )
}
