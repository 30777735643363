import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../Images/logo/unnamed.png'

export default function Navbar() {
  const closeNavbar = () => {
    const navbarToggler = document.querySelector('.navbar-toggler');
    if (navbarToggler) {
      navbarToggler.click();
    }
  };
  return (
   <>
    <nav className="navbar navbar-expand-lg bg-light">
      <div className="container-fluid">
        <Link to='\' className="navbar-brand brand fs-20" >
          <img src={logo} alt='school-logo'  className='mx-2 logo-size '/>
            Green Valley Public School
        </Link>
        <button className="navbar-toggler hamburger" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 fs-16">
            <li className="nav-item" onClick={closeNavbar}>
              <Link to='/' className="nav-link active" aria-current="page">Home</Link>
            </li>
            <li className="nav-item" onClick={closeNavbar}>
              <Link to='/AboutUs' className="nav-link" >About Us</Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Academics
              </a>
              <ul className="dropdown-menu">
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/FeeStructure">Fee Structure</Link></li>
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/SchlTiming">School Timing</Link></li>
                <li>
                  <hr className="dropdown-divider" />
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/AdmissionForm">New Admission</Link></li>
                </li>
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/ParentComponent">Download Document's</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Facilities
              </a>
              <ul className="dropdown-menu">
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/SmartClass">Smart Class</Link></li>
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/Slab">Laboratory</Link></li>
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/lib">Library</Link></li>
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/Ssports">Sports</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Disclosure
              </a>
              <ul className="dropdown-menu">
                <li onClick={closeNavbar}><Link className="dropdown-item" to="/Disclosure">CBSE Mandatory Disclosure</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Gallery
              </a>
              <ul className="dropdown-menu">
                <li onClick={closeNavbar}><Link to='/ImageGallery' className="dropdown-item" >Image Gallery</Link></li>
                <li onClick={closeNavbar}><Link to='/VideoGallery' className="dropdown-item" >Video Gallery</Link></li>
              </ul>
            </li>
            <li className="nav-item" onClick={closeNavbar}>
              <Link to='/TcForm' className="nav-link">Verify TC</Link>
            </li>
            <li className="nav-item" onClick={closeNavbar}>
              <Link to='/ContactUs' className="nav-link">Contact Us</Link>
            </li>
          </ul>
         
        </div>
      </div>
    </nav>
      <div className="whatsappWidget widgetPosition-Bottom-Right">
      <Link to='https://api.whatsapp.com/send?phone=+917900500444'><img src="https://image1.jdomni.in/jdomni_email/whatsapp_popup_2011271203.png?output-format=webp" alt='a' className="whatsappIcon" /></Link>
      </div>
    </>
  );
}
