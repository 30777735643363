import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useParams } from 'react-router-dom';
export default function ViewDocDnld(props) {
  const { pdfName } = useParams(); 

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTab: defaultTabs => {
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      // defaultTabs[1] // Thumbnails tab
    },
  });


  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={`/api/Admin/Uploads/DownldDocuments/${pdfName}`}
            plugins={[defaultLayoutPluginInstance]}
          />
      </Worker>
    </>
  );
}
