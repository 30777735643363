import React from 'react'
import libImg from '../Images/lib.jpg'

export default function lib() {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>School Library</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
  <h2>Dive into the World of Words</h2>
  <p>Enriching Minds and Imagination Through the School Library</p>
</div>
<div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1400px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
        <p className="">
        "Step into a world of knowledge, imagination, and endless possibilities at the heart of Green Valley Public School - our vibrant in-house library. With over 8000 carefully curated books, our library is not just a repository of information; it's a sanctuary of learning, exploration, and growth.
        </p>
        <p>
        <h6 style={{ color: '#5fcf80', fontFamily: 'Poppins'}}>Journey Through Words:</h6>
        From the moment you walk in, you're welcomed by a treasure trove of wisdom that spans generations. Our extensive collection spans a diverse range, ensuring that every reader - from the young explorer to the avid fiction lover - discovers a world waiting to be explored. Venture into the 'Kids Zone,' lose yourself in the pages of gripping fiction, or immerse yourself in the rich world of literature. Our library is a gateway to realms both familiar and uncharted.
        </p>
        <p>
        <h6 style={{ color: '#5fcf80', fontFamily: 'Poppins'}}>Unlock Your Inner Scholar:</h6>
        Our library isn't just a space; it's a gateway to exploration. Whether you're seeking a quiet corner to immerse yourself in words or a collaborative space to spark discussions, our library has it all. Discover the joy of flipping through pages, the thrill of uncovering new perspectives, and the power of becoming a lifelong learner.
        </p>
        <p>Join us in embracing the magic of reading, the allure of discovery, and the joy of expanding horizons. Welcome to Green Valley Public School's library - where dreams are nurtured, knowledge is celebrated, and every page is an invitation to grow."</p>
        <p className="card-text"><small className="text-body-secondary">GREEN VALLEY PUBLIC SCHOOL</small></p>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
    <div className="image-containerss shadow  my-5 bg-white rounded">
      <img src={libImg} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
    </div>
 </div>
</div>
    </>
  )
}
