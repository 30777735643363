import React from 'react'
import GVPSFEE from '../Images/GVPSFEE.jpeg'

export default function FeeStructure() {
  return (
<>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>Fee Structure</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
    <h2>Explore Our Fee Structure</h2>
    <p>Investing in Your Child's Future at Green Valley Public School</p>
</div>
<div className='d-flex align-items-center justify-content-center'>
    <img src={GVPSFEE} className="responsive" width="600" height="400" alt='GVPSFEE' />
</div>
</div>
</>
  )
}
