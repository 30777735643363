import React from 'react'
import smart from '../Images/smart.jpg'

export default function SmartClass() {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>School Smart Classes</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
  <h2>Experience the Future of Learning</h2>
  <p>Empowering Education Through Smart Classes</p>
</div>
<div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1400px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
        <p className="">
        "Welcome to Green Valley Public School, where innovation meets education! As pioneers in the region, we proudly introduce a revolutionary approach to learning: SMART CLASS. Designed to elevate learning experiences to new heights, SMART CLASS leverages cutting-edge technology to transform traditional classrooms into vibrant hubs of interactive exploration.
        </p>
        <p>
        <h6 style={{ color: '#5fcf80', fontFamily: 'Poppins'}}>Immersive Learning Adventure:</h6>
        Imagine a classroom where learning is no longer confined to textbooks and static diagrams. With SMART CLASS, education comes alive through a multisensory journey that engages all your senses. Complex concepts that once seemed distant and abstract now come to life before your eyes, bridging the gap between theory and reality. Our SMART CLASS sparks curiosity, ignites imagination, and transforms the way students perceive learning.
        </p>
        <p>
        <h6 style={{ color: '#5fcf80', fontFamily: 'Poppins'}}>Learning Beyond Boundaries:</h6>
        In today's dynamic world, education should be as versatile as the challenges that lie ahead. SMART CLASS ensures that every student receives a tailored audio-visual education that caters to the demands of the modern age. It's not just about memorizing facts; it's about understanding and application.
        </p>
        <p>By using descriptive language, emphasizing the benefits, and painting a vivid picture of the learning experience, you can create an engaging narrative that highlights the uniqueness and attractiveness of SMART CLASS technology.</p>
        <p className="card-text"><small className="text-body-secondary">GREEN VALLEY PUBLIC SCHOOL</small></p>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
    <div className="image-containerss shadow  my-5 bg-white rounded">
      <img src={smart} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
    </div>
 </div>
</div>
    </>
  )
}
