import React, { useState, useEffect } from 'react';
import DocDnld from './DocDnld';
import ViewDocDnld from './ViewDocuDnld';

export default function ParentComponent() {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    fetch(`/api/DocDnld`)
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset
        if(Array.isArray(dataArray)){
          setDocuments(dataArray)
        }else {
            console.error('Invalid data format from the API');
          }
        })
        .catch(error => {
          
        });
}, []); 

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
  };

  return (
    <>
      <DocDnld documents={documents} onDocumentClick={handleDocumentClick} />
      {console.log(documents)}
      {selectedDocument && <ViewDocDnld document={selectedDocument} />}
    </>
  );
}
