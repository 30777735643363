import React, { useState, useEffect } from 'react';

export default function RegistrationData() {
    const [data, setData] = useState([]);
    const [interviewDate, setDate] = useState('');
    const [interviewTime, setTime] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const status = ['NA', 'Selected', 'UnSelected']; 
    const [showModal, setShowModal] = useState(false);

    const handleDateChange = (e) => {
      setDate(e.target.value);
    }
    const handleTimeChange = (e) => {
      setTime(e.target.value);
    }
    const handleStatusChange = (e) => {
      setSelectedStatus(e.target.value);
    }
    const handleSubmit = (e) => {
      e.preventDefault(); // Prevents the form from submitting
    };   

    const clearInputFields = () => {
      setDate('');
      setTime('');
    };

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };

    useEffect(() => {
      fetch(`/api/RegistrationData`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setData(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
          });
    }, []);        

    const toggleSelectData = (clickedItem) =>{
      setSelectedData((prevSelectedData) => {
        if (prevSelectedData.some(data => data.mId === clickedItem.mId)) {
          return prevSelectedData.filter(item => item.mId !== clickedItem.mId);
        } else {
          return [...prevSelectedData, clickedItem];
        }
      });
    }

    const handleSchedule = (e) => {
      e.preventDefault();

      if (selectedData.length === 0) {
        alert('Please select at least one student to schedule the interview.');
        return; // Exit the function
      }
    
      // Create an array of selected student IDs
      const selectedStudentIds = selectedData.map(item => item.mId);
    
      // Create an object with scheduling data
      const schedulingData = {
        interviewDate,
        interviewTime,
        selectedStudentIds,
      };
    
      // Send the schedulingData to the server
      fetch(`/api/ScheduleInterview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(schedulingData),
      })
        .then(response => response.json())
        .then(responseData => {
          console.log('Response data:', responseData);
          // Handle the response from the server (e.g., show a success message)
          if (responseData.success) {
            setSuccessMessage('Interview scheduled successfully.');
            clearInputFields(); // Clear input fields
            setTimeout(() => {
              removeSuccessMessage();
            }, 2000); 
          }
        })
        .catch(error => {
          console.error('Error:', error);
          // Handle any errors that occur during the request
        });
    };

    const handleStatus = (e) => {
      e.preventDefault();

      if (selectedData.length === 0) {
        alert('Please select at least one student to Update The Status.');
        return; // Exit the function
      }
    
      // Create an array of selected student IDs
      const selectedStudentIds = selectedData.map(item => item.mId);
    
      // Create an object with scheduling data
      const schedulingData = {
        selectedStudentIds,
        selectedStatus,
      };
    
      // Send the schedulingData to the server
      fetch(`/api/UpdateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(schedulingData),
      })
        .then(response => response.json())
        .then(responseData => {
          if (responseData.success) {
            setSuccessMessage('staus Updated successfully.');
            setShowModal(true); 
            clearInputFields(); // Clear input fields
            setTimeout(() => {
              removeSuccessMessage();
            }, 2000); 
          }
        })
        .catch(error => {
          console.error('Error:', error);
          // Handle any errors that occur during the request
        });
    };
    
  return (
    <>
    {showModal && (
    <div className="modal-backdrop show"></div>
  )}
    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
            <div className="modal-header">
              <h5 className="modal-title">Success Message</h5>
            </div>
            <div className="modal-body">
              {successMessage && <p className='text-success'>{successMessage}</p>}
            </div>
          </div>
        </div>
      </div>
   <div>
   <div className='shadow-sm p-3 mb-3 bg-white rounded mx-3'>
    <h5 className='text-center'>Registration Data</h5>
    <form onSubmit={handleSubmit}>
        <div className='row'>
            <div className='col-md-2'>
                <div>
                    <label  className='form-label' htmlFor="title">Enter Date:</label>
                    <input  className='form-input' type="Date" value={interviewDate}  onChange={handleDateChange} required />
                </div>
            </div>
            <div className='col-md-2'>
                <div>
                    <label  className='form-label' htmlFor="title">Enter Time:</label>
                    <input  className='form-input' type="Time" value={interviewTime} onChange={handleTimeChange} required />
                </div>
            </div>
            <div className='col-md-2 mb-2'>
                <div>
                    <label  className='form-label' htmlFor="title">Status:</label>
                    <select className="form-control" id="dropdown" name="gender" value={selectedStatus} onChange={handleStatusChange}>
                    <option value="">Select an option</option>
                    {status.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
            <div className='col-md-2'>
                <div>
                    <label  className='form-label' htmlFor="title">Update Status:</label>
                    <button className='btn btn-info btn-custom' onClick={handleStatus}>Update</button>
                </div>
            </div>
            <div className='col-sm-2'>
                <div>
                    <label  className='form-label' htmlFor="title">Schedule Interview:</label>
                    <button className='btn btn-warning btn-custom' onClick={handleSchedule}>Schedule</button>
                </div>
            </div>
        </div>
    </form>
    </div>
    <div className='d-felx mx-3'>
        <div className='shadow mb-5 rounded scrollOnXaxis' style={{ maxHeight: '399px', overflowY: 'auto' }}>
        <table className='table table table-nowrap mb-0 mt-3'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>Select</th>
              <th>status</th>
              <th>Interview Date</th>
              <th>Interview Time</th>
              <th>Student Name</th>
              <th>DOB</th>
              <th>Fathers Name</th>
              <th>Fathers Occupation</th>
              <th>Mothers Name</th>
              <th>Mobile No</th>
              <th>Whatsapp No</th>
              <th>Address</th>
              <th>Apply Date</th>
            </tr>
          </thead>
          <tbody className='text-center'>
          {data.map((item, index) => (
              <tr key={index} className='text-center'>
                <td><input type="checkbox" checked={selectedData.some(selData => selData.mId === item.mId)} onChange={() => toggleSelectData(item)} /></td>
                <td>{item.status}</td>
                <td>{item.interviewDate && item.interviewDate.slice(0, 10)}</td>
                <td>{item.interviewTime}</td>
                <td>{item.name}</td>
                <td>{item.dob && item.dob.slice(0, 10)}</td>
                <td>{item.fatherName}</td>
                <td>{item.occupation}</td>
                <td>{item.motherName}</td>
                <td>{item.mobNo}</td>
                <td>{item.whatsappNo}</td>
                <td>{item.address}</td>
                <td>{item.applyDate && item.applyDate.slice(0, 10)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
   </div>
    </>
  )
}
