import React from 'react'
import schoolAbtUs from '../Images/schoolAbtUs.jpg'

export default function AboutUs() {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>About Us</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1200px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
      <h2 style={{ color: '#2D50A4', fontFamily: 'Poppins'}}>About Our School.</h2>
        <p className="">
        The school runs under Shri Bhagwan Das Smriti Nihas which is a charitable trust. The trust involve in promoting social works within boundaries. It was the dream comes true with the establishment of Green Valley Public School in 2017. The school is a non profitable organisation under the trust.
        </p>
        <p>
        Green valley public school is located in the cradle of GAIL- NTPC supported town Known as Plastic City Dibiyapur in Auraiya district. The School got its establishment in 2017 with a motive to provide best education based on learning skill of every child. The school follow CBSE curriculum and will continue up to class XII in due course of time. The promoters of the school Mr. Om Prakash Gupta & Mr. Navneet Kumar Gupta are well known figure in the field of education. The school is a dream for them.
        </p>
        <p>
        The school has its own building in a lush green area and a big play ground. The main building comprises three floors and a indoor multi- purpose area. The School includes Science labs, Library, well equipped infirmary, Projector room etc. Every class room has a smart board with required software to promote learning potential of the students.
        </p>
        <p>
        As per our future endeavour GVPS will start a hostel for boys as well. The school has proper planning to construct Houses for the Principal and the Teachers at earliest.
        </p>
        <p className="card-text"><small className="text-body-secondary">GREEN VALLEY PUBLIC SCHOOL</small></p>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
    <div className="image-containers shadow  my-5 bg-white rounded">
      <img src={schoolAbtUs} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
    </div>
 </div>
    </>
  )
}
