import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import schoolBuildingImage from '../Images/schoolBuilding.jpeg';
import directorImg from '../Images/directorImg.jpeg';
import lab from '../Images/lab.jpg';
import lib from '../Images/lib.jpg';
import smart from '../Images/smart.jpg';
import Career from '../Images/Career_GVPS.png';
import '../App.css'

export default function Feature() {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
      // Initialize purecounter animations when the component mounts
      const counters = document.querySelectorAll('.purecounter');
      counters.forEach(counter => {
        const startValue = parseInt(counter.getAttribute('data-purecounter-start'));
        const endValue = parseInt(counter.getAttribute('data-purecounter-end'));
        const duration = parseInt(counter.getAttribute('data-purecounter-duration'));
  
        animateCounter(counter, startValue, endValue, duration);
      });
    }, []);
  
    const animateCounter = (counter, start, end, duration) => {
      const increment = Math.floor((end - start) / duration);
      let startTime = null;
  
      const updateCounter = (timestamp) => {
        if (!startTime) {
          startTime = timestamp;
        }
    
        const elapsedTime = timestamp - startTime;
        if (elapsedTime >= duration * 500) {
          counter.innerText = end;
          return;
        }
    
        const value = Math.min(start + increment * (elapsedTime / (duration * 1000)), end);
        counter.innerText = Math.floor(value);
        requestAnimationFrame(updateCounter);
      };
    
      requestAnimationFrame(updateCounter);
    };

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShowModal(true);
      }, 2000);
  
      return () => clearTimeout(timeoutId);
    }, []); 

    const handleContactUs = (e) => {
      navigate('/ContactUs#contactForm')
    }


  return (
   <>
      {showModal && (
        <div className="modal-backdrop show"></div>
      )}
      <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header py-1 d-flex flex-row-reverse">
                <div className='text-end'><span onClick={() => setShowModal(false)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/></svg></span>
                </div>
              </div>
              <div className="modal-body px-4 text-center">
                <img src={Career} alt='caeer' className='careerResponsive'  />
                <button className='btn btn-danger mt-2' onClick={handleContactUs}>Apply Online!</button>
              </div>
            </div>
          </div>
      </div>
   <div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1200px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
      <h2 style={{ color: '#2D50A4', fontFamily: 'Poppins'}}>Green Valley Public School.</h2>
        <h4>A Warm Welcome To All Our Students, Staff, Parents And Visitors</h4>
        <p className="">
         GREEN VALLEY PUBLIC SCHOOL is a Child Centerd School. GVPS is a co-educational English medium school located in Dibiyapur, Auraiya. It is a rare blend of tradition and modernity.
        </p>
        <ul>
          <li><i className="bi bi-check-circle"></i> Child care and all round development have become synonyms of the school.</li>
          <li><i className="bi bi-check-circle"></i> The school has set benchmarks in quality, care and infrastructure.</li>
          <li><i className="bi bi-check-circle"></i> The schools aims at imparting quality education with special focus on all round development of child.</li>
        </ul>
        <p>
         The School's prime objective is to develop the overall personality of a child in a healthy and congenial atmosphere the school also have the state of art infastructure.
        </p>
        <Link to='/AboutUs' className='nav-link' ><p className="card-text"><small className="text-body-secondary">Learn More+</small></p></Link>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
      <img src={schoolBuildingImage} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
   <div className="card mb-1 mt-3 bg-light text-dark" style={{ maxWidth: '1200px' }}>
  <div className="row g-0">
  <div className="col-md-4 d-flex align-items-center justify-content-center">
    <img src={directorImg} className="img-fluid rounded-start" alt="..." />
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Chairman message</h5>
        <p className="card-text">
        I welcome you and your child to a new experience in education. We at Green Valley Public School Dibiyapur, endeavour to provide a curriculum with a difference. One that balances excellence in learning with an all-round cultural, physical, mental and moral development. Our attempt is to make the most formative and beautiful years of our students’ lives a joy forever.
        </p>
        <p className="card-text">
        We try to build ocean liners with Indian anchors and ethical rudders. A new age CBSE school is welcoming all of you with open arms to revive those Indian Values which today seems to be outdated and has lost its sheen.
        </p>
        <p className="card-text"><small className="text-body-secondary">Thank You</small></p>
        <p className="card-text">Mr. Navneet Kumar Gupta</p>
      </div>
    </div>
    </div>
</div>
</div>

{/* Testimonal Section */}

<div className="container counts mb-2 py-3 bg-light text-success" style={{ maxWidth: "1400px"}}>
  <div className="row counters">
    <div className="col-lg-3 col-6 text-center">
      <div className='d-flex justify-content-center'><span data-purecounter-start="0" data-purecounter-end="2532" data-purecounter-duration="1" className="purecounter"></span><span>+</span></div>
      <p>Students</p>
    </div>
    <div className="col-lg-3 col-6 text-center">
      <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" className="purecounter"></span>
      <p>Courses</p>
    </div>
    <div className="col-lg-3 col-6 text-center">
      <span data-purecounter-start="0" data-purecounter-end="42" data-purecounter-duration="2" className="purecounter"></span>
      <p>Events</p>
    </div>
    <div className="col-lg-3 col-6 text-center">
      <div className='d-flex justify-content-center'><span data-purecounter-start="0" data-purecounter-end="100+" data-purecounter-duration="3" className="purecounter"></span><span>+</span></div>
      <p>Teachers</p>
    </div>
  </div>
</div>

{/* School Facilities Section  */}

<div id="popular-courses" className="courses my-5">
  <div className="container aos-init aos-animate" data-aos="fade-up">
    <div className="section-title">
      <h2>Facilities</h2>
      <p>School Facilities</p>
    </div>

  <div className="row aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
      <div className="course-item">
        <img src={lab} className="img-fluid" alt="..." />
        <div className="course-content">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Link to='/Slab' className='nav-link' ><h4>LABORATORIES</h4></Link>
          </div>
        <h3><a href="course-details.html">School Lab</a></h3>
        <p>Laboratories are essential part of modern education to enable students to experience and visualize concept. The school has modern laboratories for science and computer with all the necessary items for items for experiments and help in teaching.</p>
        <div className="trainer d-flex justify-content-between align-items-center">
        <div className="trainer-profile d-flex align-items-center">
        <img src='' className="img-fluid" alt="" />
        <span className='text-white'>GVPS</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
      <div className="course-item">
        <img src={lib} className="img-fluid" alt="..." />
        <div className="course-content">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Link to='/lib' className='nav-link' ><h4>LIBRARY</h4></Link>
          </div>
        <h3><a href="course-details.html">School Library</a></h3>
        <p>The school has well stocked library. Besides books related to curriculum and competitive examination, the school library also contain books of science, history, grammer etc. which are very essential for student's all round development.</p>
        <div className="trainer d-flex justify-content-between align-items-center">
          <div className="trainer-profile d-flex align-items-center">
            <img src="assets/img/trainers/trainer-2.jpg" className="img-fluid" alt="" />
            <span  className='text-white'>GVPS</span>
          </div>
                </div>
        </div>
      </div>
    </div> 

    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
      <div className="course-item">
        <img src={smart} className="img-fluid" alt="..." />
        <div className="course-content">
          <div className="d-flex justify-content-between align-items-center mb-3">
           <Link to='/SmartClass' className='nav-link' ><h4>SMART CLASSES</h4></Link>
          </div>
          <h3><a href="course-details.html">School Smart Classes</a></h3>
          <p>Green Valley Public School is the first school in the region with a new age technology to enhance the learning skills through smart class. An EdTech-upgraded classroom that enhances the teaching and learning process for both the teachers and the students.</p>
          <div className="trainer d-flex justify-content-between align-items-center">
            <div className="trainer-profile d-flex align-items-center">
              <img src="assets/img/trainers/trainer-3.jpg" className="img-fluid" alt="" />
              <span  className='text-white'>GVPS</span>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</div>
</div>
   </>
  );
}
