import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '../App.css';

export default function ViewTcFrm() {
    const[pdf, setPdf] = useState([]);
    const[fromDate, setFromDate] = useState('');
    const[toDate, setToDate] = useState('');
    const[selectedPdf, setSelectedPdf] = useState([]);
    const[selectedViewPdf, setSelectedViewPdf] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error] = useState(null);

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
      sidebarTab: defaultTabs => {
        return [defaultTabs[0], defaultTabs[1]];
      },
    });

    const handlefromDateChange = (e) =>{
        setFromDate(e.target.value);
    }
    const handletoDateChange = (e) =>{
        setToDate(e.target.value);
    }


    const handleSubmit = (e) =>{
       e.preventDefault();

    const apiUrl = `/api/ViewTcFrm?fromDate=${fromDate}&toDate=${toDate}`;
    // console.log('API URL:', apiUrl);

    fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset
        if(Array.isArray(dataArray)){
          setPdf(dataArray)
          setLoading(false);
        }else {
            console.error('Invalid data format from the API');
          }
        })
    .catch((error) => {
      console.error('Error fetching Pdf:', error);
      // Handle the error
    });

    };

    useEffect(() => {
      fetch(`/api/ViewTcFrm`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setPdf(dataArray)
            setLoading(false);
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {

          });
    }, []); 

    const toggleSelectdPdf = (clickedPdf) => {
        if (selectedPdf.some(pdf => pdf.id === clickedPdf.id)) {
            setSelectedPdf(selectedPdf.filter(pdf => pdf.id !== clickedPdf.id));
        } else {
            setSelectedPdf([...selectedPdf, clickedPdf]);
        }
    }
    
    const handleDelete = () => {
      if (selectedPdf.length === 0) {
        alert('Select at least one Pdf to delete.');
        return;
      }
  
      const selectedPdfIds = selectedPdf.map(pdf => pdf.id);
  
      fetch(`/api/deleteSelectedPdf`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids: selectedPdfIds })
      })
      .then(response => response.json())
      .then(data => {
        console.log('pdf deleted:', data);
         // Remove deleted images from the local state
         const updatedpdf = pdf.filter(pdf => !selectedPdfIds.includes(pdf.id));
         setPdf(updatedpdf);
  
         // Clear the selected images
         setSelectedPdf([]);
       })
       .catch(error => console.error('Error deleting images:', error));
    };
      
return (
<>
<div className='mx-3 my-3' >
  <div className='shadow-sm p-3 bg-white rounded mb-5'>
    <h1 className='text-center'>TC Records</h1>
      <form className='row  mx-0 d-flex justify-content-around m-form' onSubmit={handleSubmit}>
        <div className='col-auto'>
          <label  className='form-label' htmlFor="title">From Date:</label>
        </div>
        <div className='col-auto'>
          <input  className='form-input' type="Date" id="fromDate" name='fromDate' value={fromDate}  onChange={handlefromDateChange} required />
        </div>
        <div className='col-auto'>
          <label  className='form-label' htmlFor="image">To Date:</label>
        </div>
        <div className='col-auto'>
          <input  className='form-input' type="Date"  name="toDate" id="toDate"  value={toDate} onChange={handletoDateChange} required />
        </div>
        <div className='col-auto'>
          <button className='form-submit' type="submit">Apply Filter</button>
        </div>
      </form>
    </div>
  <div className='d-felx'>
      {loading ? (
            <p>Loading...</p>
            ) : error ? (
              <p>Error: {error.message}</p>
            ) :Array.isArray(pdf) && pdf.length > 0 ? (
      <table className='table table-nowrap mb-0 mt-3'>
        <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
          <tr>
            <th>Select</th>
            <th>Date of added</th>
            <th>DOB</th>
            <th>Sr No</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody className='text-center'>
        {pdf.map(pdf => (
          <tr key={pdf.id} className='text-center '>
            <td>
            <input type="checkbox" checked={selectedPdf.some(selPdf => selPdf.id === pdf.id)} onChange={() => toggleSelectdPdf(pdf)} />
            </td>
            <td>{pdf.upldDate.slice(0, 10)}</td>
            <td>{new Date(pdf.DOB).toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' })}</td>
            <td>{pdf.SrNo}</td>
            <td><p className='badge badge-success-lighten' onClick={() => setSelectedViewPdf(pdf)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/><path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/></svg></p></td>
          </tr>
        ))}
      </tbody>
      </table>
       ) : (
        <p>No Data Founded.
            {console.log('Data is not an array')}
        </p>
      )}
      </div>
      <form method="GET" className='form1 my-2'>
      <button type="submit" className='btn btn-danger' onClick={handleDelete}  disabled={selectedPdf.length === 0}>Delete Selected Pdf</button>
      </form>
      </div>
      <div style={{ width: '30%'}}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          {selectedViewPdf && selectedViewPdf.PdfName && (
            <div className="pdf-viewer">
              <a href='/Admin/ViewTcFrm'>Go Back</a>
              <div className="pdf-viewer-content">
                <Viewer fileUrl={`/api/Admin/Uploads/TransferCertificate/${selectedViewPdf.PdfName}`} plugins={[defaultLayoutPluginInstance]} />
              </div>
            </div>
          )}
        </Worker>
      </div>
    </>
  )
}
