import React from 'react'
import labImg from '../Images/lab.jpg'

export default function lab() {
  return (
    <>
<div className='container carouselImg' style={{ maxWidth: '1400px' }}>
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active image-abtUscontainer ">
        <div className='overlay'>
        <div className="centered-text">
        <h2>School Laboratory</h2>
        </div>
        </div>
      {/* <img src={imgGalCarosel} className="d-block responsive" alt="..." width={'600px'} height={'400'}/> */}
    </div>
  </div>
</div>
</div>
<div className='container my-5'>
<div className="csection-title">
  <h2>Explore Our State-of-the-Art Laboratories</h2>
  <p>Igniting Curiosity and Learning Through Hands-On Experiments</p>
</div>
<div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1400px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-8">
      <div className="card-body">
        <p className="">
        "Welcome to a world of discovery and exploration at Green Valley Public School! Our commitment to modern education is beautifully manifested through our state-of-the-art laboratories, where learning goes beyond textbooks and takes on a vibrant, hands-on approach.
        </p>
        <p>
        <h6 style={{ color: '#5fcf80', fontFamily: 'Poppins'}}>Igniting Curiosity, Illuminating Minds:</h6>
        In our belief that true understanding emerges from firsthand experiences, we've crafted laboratories that transform theories into tangible realities. Our laboratories are not just rooms; they are realms of experimentation, imagination, and discovery. From witnessing chemical reactions that dance with colors to peering through the lens of a microscope to uncover the hidden wonders of life, our laboratories offer a space to ignite curiosity and illuminate young minds.
        </p>
        <p>
        <h6 style={{ color: '#5fcf80', fontFamily: 'Poppins'}}>Inspiring Minds, Building Futures:</h6>
        Our laboratories are more than just rooms with equipment; they are catalysts of inspiration and builders of futures. We believe that hands-on experiences solidify understanding, and practical learning breeds excellence. Through our laboratories, we aim to transform theoretical concepts into tangible skills, fostering a love for learning that resonates far beyond the classroom.
        </p>
        <p>Join us on a journey where experimentation knows no bounds, where exploration fuels knowledge, and where our laboratories stand as gateways to a world of endless possibilities. Welcome to Green Valley Public School, where learning is an experience, and laboratories are the portals to unlocking the universe of knowledge."</p>
        <p className="card-text"><small className="text-body-secondary">GREEN VALLEY PUBLIC SCHOOL</small></p>
      </div>
    </div>
    <div className="col-md-4 d-flex align-items-center">
    <div className="image-containerss shadow  my-5 bg-white rounded">
      <img src={labImg} className="img-fluid rounded-start" alt="..." />
    </div>
    </div>
    </div>
    </div>
 </div>
</div>
    </>
  )
}
