import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import PhotoRoundedIcon from "@mui/icons-material/PhotoRounded";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";

export default function AdminHome() {
  const [collapsed, setCollapsed] = useState(false);
  
  const collapseSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    fetch('/Logout', {
      method: 'POST',
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = '/Admin/LogIn';
          window.localStorage.removeItem("isLoggedIn");
        }
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };

  let inactivityTimeout;

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(handleLogout, 10 * 60 * 1000);
  };

  resetInactivityTimeout();
  document.addEventListener('mousemove', resetInactivityTimeout);
  document.addEventListener('keydown', resetInactivityTimeout);
  
  return (
    <>
<div className='d-none d-md-flex' style={{ display: "flex", minHeight: "100vh"}}>
        <Sidebar className="app" collapsed={collapsed}>
          <Menu>
            <MenuItem className="menu1 sidebar-item"  icon={<MenuRoundedIcon className='icon' onClick={collapseSidebar} />}>
            <div className="text-center mt-3">
              <h6 className="text"><strong>Green Valley Public<br/><span className="d-inline-block">SCHOOL</span></strong></h6>
            </div>
            </MenuItem>
            <SubMenu className="sidebar-item"  label={<span className="text">Banner Section</span>} icon={<FlagRoundedIcon className="icon"  />}>
              <MenuItem className="sidebar-item" component={<Link to="/Admin/ImgUploadForm" className="link" />}  icon={<TimelineRoundedIcon className="text-item"  />}> <span className="text-item">Add Banner</span> </MenuItem>
              <MenuItem className="sidebar-item" component={<Link to="/Admin/ViewBannerForm" className="link" />}  icon={<BubbleChartRoundedIcon className="text-item"  />}><span className="text-item">View/Add Banner</span></MenuItem>
            </SubMenu>
            <SubMenu className="sidebar-item"  label={<span className="text">Image Gallery</span>} icon={<ImageRoundedIcon className="icon"  />}>
              <MenuItem className="text-item" component={<Link to="/Admin/SchlImgGalryUpldFrm" className="link" />}  icon={<PhotoRoundedIcon className="text-item"  />}>
              <span className="text-item">Add Events</span>
              </MenuItem>
              <MenuItem className="sidebar-item" component={<Link to="/Admin/ViewSchlImgGalryFrm" className="link" />}  icon={<PhotoRoundedIcon className="text-item"  />}><span className="text-item">View Events</span></MenuItem>
            </SubMenu>
            <SubMenu className="sidebar-item"  label={<span className="text">video Gallery</span>} icon={<MovieRoundedIcon className="icon"  />}>
              <MenuItem className="text-item" component={<Link to="/Admin/VideoUpldFrm" className="link" />}  icon={<VideocamRoundedIcon className="text-item"  />}> <span className="text-item">Add Video</span> </MenuItem>
            </SubMenu>
            <SubMenu className="sidebar-item"  label={<span className="text">News & Update</span>} icon={<ArticleRoundedIcon className="icon"  />}>
              <MenuItem className="text-item" component={<Link to="/Admin/NoticeForm" className="link" />}  icon={<EventNoteRoundedIcon className="text-item"  />}> <span className="text-item">Add News</span> </MenuItem>
              <MenuItem className="text-item" component={<Link to="/Admin/ViewNews" className="link" />}  icon={<NewReleasesRoundedIcon className="text-item"  />}> <span className="text-item">View News</span> </MenuItem>
            </SubMenu>
            <SubMenu className="sidebar-item"  label={<span className="text">Document Section</span>} icon={<ReceiptRoundedIcon className="icon"  />}>
              <MenuItem className="text-item" component={<Link to="/Admin/DocumentDwnld" className="link" />}  icon={<ReceiptRoundedIcon className="text-item"  />}> <span className="text-item">Add Documents</span> </MenuItem>
            </SubMenu>
            <SubMenu className="sidebar-item"  label={<span className="text">TC Section</span>} icon={<AccountBalanceRoundedIcon className="icon"  />}>
              <MenuItem className="text-item" component={<Link to="/Admin/TcUpldFrm" className="link" />}  icon={<EventNoteRoundedIcon className="text-item"  />}> <span className="text-item">Add TC Details</span> </MenuItem>
              <MenuItem className="text-item" component={<Link to="/Admin/ViewTcFrm" className="link" />}  icon={<ReceiptRoundedIcon className="text-item"  />}> <span className="text-item">View TC Details</span> </MenuItem>
            </SubMenu>
            <SubMenu className="sidebar-item"  label={<span className="text">Client Enquiries</span>} icon={<ChatRoundedIcon className="icon"  />}>
              <MenuItem className="text-item" component={<Link to="/Admin/ViewEnquiryData" className="link" />}  icon={<ForumRoundedIcon className="text-item"  />}> <span className="text-item">View Enquiry Data</span> </MenuItem>
              <MenuItem className="text-item" component={<Link to="/Admin/RegistrationData" className="link" />}  icon={<MessageRoundedIcon className="text-item"  />}> <span className="text-item">View Registration Data</span> </MenuItem>
            </SubMenu>
          </Menu>
        </Sidebar>
    </div>
    </>
  )
}
