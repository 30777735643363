import React, { useState, useEffect } from 'react';
import '../CSS/Admin_Home.css';

export default function ViewBannerForm() {
  const [image, setImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    fetch(`/api/ViewBannerForm`)
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset
        if(Array.isArray(dataArray)){
          setImage(dataArray)
        }else {
            console.error('Invalid data format from the API');
          }
        })
        .catch(error => {
          
        });
  }, []); 

  const handleStatusChange = (clickedImage) => {
    console.log('Image ID:', clickedImage.id);
    console.log('Image Data:', image);
    const newStatus = clickedImage.status === 0 ? 1 : 0; // Toggle the status value
    const updatedImages = image.map(img =>
      img.id === clickedImage.id ? { ...img, status: newStatus } : img
    );

    // Update the state to reflect the new status locally
    setImage(updatedImages);

    // Make a network request to update the status in the database
    fetch(`/api/gvps-carousel/${clickedImage.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: clickedImage.id, newStatus })
    })
      .then(response => response.json())
      .then(data => console.log('Status updated:', data))
      .catch(error => console.error('Error updating status:', error));
  };

  const toggleSelectImage = (clickedImage) => {
    if (selectedImages.some(image => image.id === clickedImage.id)) {
      setSelectedImages(selectedImages.filter(image => image.id !== clickedImage.id));
    } else {
      setSelectedImages([...selectedImages, clickedImage]);
    }
  };

  const handleDeleteBanner = () => {
    if (selectedImages.length === 0) {
      alert('Select at least one image to delete.');
      return;
    }

    // Send a DELETE request to delete selected images from the database and image files
    const selectedImageIds = selectedImages.map(img => img.id);

    fetch(`/api/deleteSelectedBanners`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ids: selectedImageIds })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Images deleted:', data);
       // Remove deleted images from the local state
       const updatedImages = image.filter(img => !selectedImageIds.includes(img.id));
       setImage(updatedImages);

       // Clear the selected images
       setSelectedImages([]);
     })
     .catch(error => console.error('Error deleting images:', error));
  };

  return (
    <>
      <div className='container d-felx mt-5 custom-container' >
      <h1 className='text-center'>View Banner</h1>
      <table className='table table-nowrap mb-0 mt-3'>
        <thead className='table-primary'>
          <tr>
            <th>Select Images</th>
            <th>Banner</th>
            <th>Banner Title</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className='text-center'>
          {image.map(image => (
            <tr key={image.id} className='text-center '>
              <td>
              <input type="checkbox" checked={selectedImages.some(selImage => selImage.id === image.id)} onChange={() => toggleSelectImage(image)} />
            </td>
              <td><img src={`/api/Admin/Uploads/BannerImages/${image.imgUrl}`} alt="Banner" className="banner-image " /></td>
              <td>{image.title}</td>
              <td>{image.description}</td>
              <td>
              <div className="form-check form-switch d-flex text-center">
              <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckChecked-${image.id}`}
            checked={image.status === 0 ? true : false} 
            onChange={() => handleStatusChange(image)} // Call the event handler
          />
          <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${image.id}`}>
            {image.status === 0 ? 'Active' : 'Inactive'}
          </label>
          </div>
          </td>
          </tr>
          ))}
        </tbody>
      </table>
      <form className='form1 my-2'>
      <button type='button' className='btn btn-danger' onClick={handleDeleteBanner}  disabled={selectedImages.length === 0}>
            Delete Selected Banners
          </button>
      </form>
      </div>
    </>
  );
}